<template>
  <div class="pl-md-3 pl-xs-1 d-flex flex-row align-center">
    <v-toolbar-title class="mr-2">
      {{ heading }}
    </v-toolbar-title>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('home')"
        >
          <v-icon>{{ iconBack }}</v-icon>
        </v-btn>
      </template>
      <span v-if="$route.name === 'terminated.detail'">{{
        $t("tooltip.back-to-terminated-list")
      }}</span>
      <span v-else>{{ $t("tooltip.back-to-revoked-list") }}</span>
    </v-tooltip>

    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="ml-md-4 ml-sm-0"
          v-bind="attrs"
          v-on="on"
          text
          icon
          color="blue lighten-2 ml-3"
          @click="menuAction('reload')"
        >
          <v-icon>{{ iconReload }}</v-icon>
        </v-btn>
      </template>
      <span>{{ $t("tooltip.reload") }}</span>
    </v-tooltip>

    <v-menu bottom left>
      <template v-slot:activator="{ on: menu, attrs }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              class="ml-2"
              icon
              v-bind="attrs"
              v-on="{ ...tooltip, ...menu }"
              color="blue lighten-2 ml-3"
            >
              <v-icon>{{ iconCog }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("tooltip.other-menu") }}</span>
        </v-tooltip>
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in menus"
          :key="i"
          class="cursor-pointer memo-header-menu"
          @click="menuAction(item.command)"
        >
          <v-icon class="mr-3">{{ item.icon }}</v-icon>
          <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { mdiReload, mdiDotsVertical, mdiBackburger, mdiCog } from "@mdi/js";
import toolbarMenu from "@/constants/toolbarmenu";
export default {
  name: "header-left",
  components: {},
  props: {
    heading: {
      type: String,
      default: "",
    },
    enableAction: {
      type: Boolean,
    },
    actionMenu: {
      type: [Object, Array],
    },
  },
  data: () => ({
    iconBack: mdiBackburger,
    iconReload: mdiReload,
    iconMenu: mdiDotsVertical,
    iconCog: mdiCog,
    menus: toolbarMenu.dispositionDetailKHFMenu,
  }),

  computed: {
    ...mapState("user", ["currentUser"]),
  },
  methods: {
    menuAction(command) {
      this.$emit("menuAction", command);
    },
  },
};
</script>