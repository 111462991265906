<template>
  <div>
    <v-row>
      <v-col md="6" sm="12" class="d-flex flex-column">
        <v-card class="flex" outlined tile>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                {{ $t("card.detail-info") }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="red--text text--darken-1 text-bolder text-uppercase"
              >
                <v-icon class="red--text text--lighten-1">{{
                  iconInfo
                }}</v-icon>
                {{
                  singleTerminatedRevoked
                    ? singleTerminatedRevoked.memo.status_label
                    : ""
                }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text>
            <div class="table-responsive-container">
              <div class="table-responsive info__container">
                <table class="draft__info-table">
                  <tr>
                    <td>{{ $t("label.recipients") }}</td>
                    <td>:</td>
                    <td class="recipient__column">
                      <v-chip
                        v-for="item in recipients"
                        :key="item.id"
                        class="mr-1 mb-1 recipient__chip"
                      >
                        {{ item.nama }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" style="height: 20px">&nbsp;</td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.draft-status") }}</td>
                    <td>:</td>
                    <td>
                      {{
                        singleTerminatedRevoked
                          ? singleTerminatedRevoked.memo.status_label
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.created-by") }}</td>
                    <td>:</td>
                    <td>
                      {{
                        singleTerminatedRevoked
                          ? singleTerminatedRevoked.creator.nama
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t("label.created-at") }}</td>
                    <td>:</td>
                    <td>
                      {{
                        singleTerminatedRevoked
                          ? indoDate(singleTerminatedRevoked.memo.created_at)
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3" style="height: 20px">&nbsp;</td>
                  </tr>
                  <tr>
                    <td v-if="$route.name === 'terminated.detail'">
                      {{ $t("label.terminated-by") }}
                    </td>
                    <td v-else>{{ $t("label.revoked-by") }}</td>
                    <td>:</td>
                    <td v-if="$route.name === 'terminated.detail'">
                      {{
                        singleTerminatedRevoked
                          ? singleTerminatedRevoked.memo.nama_reviser_terakhir
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        singleTerminatedRevoked
                          ? singleTerminatedRevoked.revocation.creator
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$route.name === 'terminated.detail'">
                      {{ $t("label.terminated-at") }}
                    </td>
                    <td v-else>{{ $t("label.revoked-at") }}</td>
                    <td>:</td>
                    <td v-if="$route.name === 'terminated.detail'">
                      {{
                        singleTerminatedRevoked
                          ? indoDate(singleTerminatedRevoked.memo.terminated_at)
                          : ""
                      }}
                    </td>
                    <td v-else>
                      {{
                        singleTerminatedRevoked
                          ? indoDate(
                              singleTerminatedRevoked.revocation.created_at
                            )
                          : ""
                      }}
                    </td>
                  </tr>
                  <tr>
                    <td v-if="$route.name === 'terminated.detail'">
                      {{ $t("label.terminated-comment") }}
                    </td>
                    <td v-else>{{ $t("label.terminated-comment") }}</td>
                    <td>:</td>
                    <td>
                      <span class="red--text text--darken-2">
                        {{ singleTerminatedRevoked.revocation.reason }}
                      </span>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="6" sm="12" class="d-flex flex-column">
        <v-card class="flex" outlined tile>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                {{ $t("card.draft-tracking") }}
              </v-list-item-title>
              <v-list-item-subtitle>{{
                $t("text.traces-the-concept-until-terminated")
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-text class="mx-2">
            <div class="table-responsive max-h-500" v-if="trackingList">
              <div>
                <ul class="history-awb-timeline">
                  <li v-for="(item, index) in trackingList" :key="index">
                    <div class="d-flex flex-row">
                      <div
                        class="history-date d-flex flex-column"
                        v-if="isUniqueDate(index)"
                      >
                        <p>{{ mydate(item.created_at, "dayname") }},</p>
                        <h5>{{ mydate(item.created_at, "date") }}</h5>
                      </div>
                      <div class="history-date d-flex flex-column" v-else>
                        <p></p>
                        <h5></h5>
                      </div>
                      <div class="history-detail d-flex flex-column">
                        <p style="margin: 0px">
                          {{ mydate(item.created_at, "time") }}
                        </p>
                        <p style="margin: 0px" v-html="item.action"></p>
                        <p v-html="item.desc"></p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import {
  mdiAccount,
  mdiAccountGroup,
  mdiInformation,
  mdiCalendarTextOutline,
} from "@mdi/js";
export default {
  name: "history-detail",
  props: {
    singleTerminatedRevoked: { type: [Object, Array] },
  },
  data: () => ({
    iconUser: mdiAccount,
    iconGroup: mdiAccountGroup,
    iconInfo: mdiInformation,
    iconLog: mdiCalendarTextOutline,
    showLogDialog: false,
    showRevInfoDialog: false,
    memoRevId: undefined,
    reviserName: undefined,
  }),
  computed: {
    ...mapState("draft", ["singleDraftHistory"]),
    trackingList() {
      if (!this.singleDraftHistory) return [];
      return this.singleDraftHistory;
    },
    recipients() {
      if (!this.singleTerminatedRevoked) return undefined;
      return this.singleTerminatedRevoked.penerima;
      // let result = this.singleTerminatedRevoked.penerima.map(({ name }) => name);
      // return result.join();
    },
    revisers() {
      if (!this.singleTerminatedRevoked) return undefined;
      return this.singleTerminatedRevoked.revisers;
    },
  },
  methods: {
    ...mapActions("draft", [
      "setDraftHistory",
      "getDraftHistory",
      "setDraftLog",
      "setSingleRevisionInfo",
    ]),
    isUniqueDate(trackingIndex) {
      return this.checkDateTrackingItem(trackingIndex);
    },
    showHideLogDialog(val) {
      this.showLogDialog = val;
      if (!val) {
        this.setDraftLog(undefined);
      }
    },
    showHideRevInfoDialog(val) {
      this.showRevInfoDialog = val;
      if (!val) {
        this.memoRevId = undefined;
        this.reviserName = undefined;
        this.setSingleRevisionInfo(undefined);
      }
    },
    // showRevisionDetails(revId, reviserName ){
    //   if (!revId) return;
    //   this.memoRevId = {memoId : this.singleDraft.memo.id_hash, revisionId : revId};
    //   this.reviserName = reviserName;
    //   this.showRevInfoDialog = true;
    // },
    checkDateTrackingItem(trackingIndex) {
      const previousItem = this.singleDraftHistory[trackingIndex - 1];
      // const nextItem = this.trackingItems[trackingIndex + 1];
      const currentItem = this.singleDraftHistory[trackingIndex];
      if (!previousItem) {
        return true;
      }
      const prevDate = this.mydate(previousItem.created_at, "date");
      // const nextDate = nextItem ? nextItem.date : null;
      const currDate = this.mydate(currentItem.created_at, "date");

      if (prevDate !== currDate) return true;
      return false;
    },
  },
};
</script>